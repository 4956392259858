import { Children } from "react";
import { useTrail, a } from "@react-spring/web";

export const Trail = ({ open, children, height = 60, duration = 400 }) => {
  const items = Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 5, tension: 2000, friction: 200, duration: duration },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? height : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });
  return (
    <div>
      {trail.map(({ height, ...style }, index) => (
        <a.div
          key={index}
          className="relative overflow-hidden w-full text-xl text-gray-200 text-center"
          style={style}
        >
          <a.div style={{ height }}>{items[index]}</a.div>
        </a.div>
      ))}
    </div>
  );
};
