export const FORM_INITIAL_STATE = {
  name: "",
  experience: "",
  problem: "",
  solveProblem: "",
  somethingToSay: "",
  emplId: "",
};

export const formReducer = (state, action) => {
  switch (action.type) {
    case "NAME_INPUT":
      return {
        ...state,
        name: action.payload,
      };
    case "EXP_INPUT":
      return {
        ...state,
        experience: action.payload,
      };
    case "PROBLEM_INPUT":
      return {
        ...state,
        problem: action.payload,
      };
    case "SOLVE_PROBLEM_INPUT":
      return {
        ...state,
        solveProblem: action.payload,
      };
    case "SOMETHING_TO_SAY_INPUT":
      return {
        ...state,
        somethingToSay: action.payload,
      };
    case "EMPL_ID_INPUT":
      return {
        ...state,
        emplId: action.payload,
      };
    default:
      return state;
  }
};
