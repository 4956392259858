import { useState, useEffect, Suspense } from "react";
import "../App.css";

const BackgroundImage = ({ step, children }) => {
  //const [backgroundImage, setBackgroundImage] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const images = [
      "./BG1.gif",
      "./BG2.gif",
      "./BG3.gif",
      "./BG4.gif",
      "./BG5.gif",
      "./BG6.gif",
      "./BG7.gif",
      "./BG8.png",
    ];

    images.forEach((image) => {
      const img = new Image();
      img.src = image;
      img.addEventListener("load", () => setIsLoaded(true));
    });
  }, []);

  // useEffect(() => {
  //   const handleChangeBackgroundImage = () => {
  //     if (step < 3) {
  //       //setBackgroundImage(`url(${Image1})`);
  //       setBackgroundImage(`url("./BG1.gif")`);
  //       return;
  //     }
  //     if (step < 7) {
  //       //setBackgroundImage(`url(${Image2})`);
  //       setBackgroundImage(`url("./BG2.gif")`);
  //       return;
  //     }
  //     if (step < 10) {
  //       //setBackgroundImage(`url(${Image3})`);
  //       setBackgroundImage(`url("./BG3.gif")`);
  //       return;
  //     }
  //     if (step < 13) {
  //       //setBackgroundImage(`url(${Image4})`);
  //       setBackgroundImage(`url("./BG4.gif")`);
  //       return;
  //     }
  //     if (step < 16) {
  //       //setBackgroundImage(`url(${Image5})`);
  //       setBackgroundImage(`url("./BG5.gif")`);
  //       return;
  //     }
  //     if (step < 17) {
  //       //setBackgroundImage(`url(${Image6})`);
  //       setBackgroundImage(`url("./BG6.gif")`);
  //       return;
  //     }
  //     if (step < 18) {
  //       //setBackgroundImage(`url(${Image7})`);
  //       setBackgroundImage(`url("./BG7.gif")`);
  //       return;
  //     }
  //     setBackgroundImage(`url("./BG8.png")`);
  //   };
  //   handleChangeBackgroundImage();
  // }, [step]);
  return (
    <Suspense fallback={<p>Loading</p>}>
      {!isLoaded ? (
        <p>Loading...</p>
      ) : (
        <section
          className="relative mx-auto bg-cover bg-no-repeat bg-center w-full h-full image-container"
          style={{
            backgroundImage:
              step < 3
                ? `url("./BG1.gif")`
                : step < 7
                ? `url("./BG2.gif")`
                : step < 10
                ? `url("./BG3.gif")`
                : step < 13
                ? `url("./BG4.gif")`
                : step < 16
                ? `url("./BG5.gif")`
                : step < 17
                ? `url("./BG6.gif")`
                : step < 18
                ? `url("./BG7.gif")`
                : `url("./BG8.png")`,
          }}
        >
          {children}
        </section>
      )}
    </Suspense>
  );
};

export default BackgroundImage;
