import { useRef, useReducer, useCallback, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { toJpeg } from "html-to-image";
import axios from "axios";
import useSound from "use-sound";
import {
  FacebookShareButton,
  LineShareButton,
  FacebookIcon,
  LineIcon,
} from "react-share";
import { VscMute, VscUnmute } from "react-icons/vsc";
import { Trail } from "./components/Trail";
import { formReducer, FORM_INITIAL_STATE } from "./reducer/FormReducer";
import {
  formStateReducer,
  FORM_STATE_INITIAL_STATE,
} from "./reducer/FormStateReducer";
import bgSound from "./sound/sound.mp3";
import BackgroundImage from "./components/BackgroundImage";

function App() {
  const [state, dispatch] = useReducer(formReducer, FORM_INITIAL_STATE);
  //const [isVisible, setIsVisible] = useState(true);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [formState, dispatchFromState] = useReducer(
    formStateReducer,
    FORM_STATE_INITIAL_STATE
  );
  const secondSection = useRef(null);
  const [play, { pause }] = useSound(bgSound);

  useEffect(() => {
    setIsSoundOn(true);
    play();
  }, [play]);

  const postData = async () => {
    const data = {
      name: state.name,
      experience: state.experience,
      problem: state.problem,
      solveProblem: state.solveProblem,
      somethingToSay: state.somethingToSay,
      emplId: state.emplId,
    };
    const config = {
      method: "post",
      url: "https://asia-southeast1-eighth-network-339910.cloudfunctions.net/googleForm",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    //console.log(res);
  };

  const scrollToSecondSection = () => {
    // setIsVisible(false);
    //secondSection.current.scrollIntoView();
    dispatchFromState({
      type: "CHANGE_STEP",
      payload: 3,
    });
  };

  const onButtonClick = useCallback(() => {
    if (secondSection.current === null) {
      return;
    }

    const filter = (node) => {
      const exclusionClasses = ["remove-btn"];
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      );
    };

    toJpeg(secondSection.current, {
      quality: 0.95,
      cacheBust: true,
      filter: filter,
    })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image.jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [secondSection]);

  const playSound = () => {
    if (isSoundOn) {
      pause();
      setIsSoundOn((prev) => !prev);
    }
    if (!isSoundOn) {
      play();
      setIsSoundOn((prev) => !prev);
    }
  };

  return (
    <div className="relative">
      <button onClick={playSound} className="absolute top-2 right-2 z-50">
        {isSoundOn && <VscMute color="white" size={30} />}
        {!isSoundOn && <VscUnmute color="white" size={30} />}
      </button>

      <BackgroundImage step={formState.step}>
        <div className="min-h-screen w-80 mx-auto flex flex-col items-center justify-center relative">
          <Trail open={formState.step === 1}>
            <span>เว็บไซต์นี้ไม่มีการเก็บข้อมูล</span>
            <span>คุณสามารถปล่อยใจสบาย</span>
            <span>เล่าเรื่องราวอย่างเป็นตัวเองได้เลย</span>
          </Trail>

          <Trail open={formState.step === 2.1}>
            <span>โปรเจกต์ส้มเขียวหวาน</span>
            <span>เกิดจากการคิดถึงการทำงานตลอดปี</span>
            <span>ของพนักงาน AOT </span>
            <span>เราเชื่อว่าการทำงานอย่างหนัก</span>
          </Trail>

          <Trail open={formState.step === 2.2}>
            <span>อาจเหนื่อยหรือท้อและมีน้ำตา</span>
            <span>การเดินทางสู่ความสำเร็จ</span>
            <span>มันอาจไม่ได้โรยด้วยกลีบกุหลาบ</span>
            <span>เราจึงจัดทำโปรเจกต์นี้ เพื่อสร้างกำลังใจ</span>
          </Trail>

          <Trail open={formState.step === 2.3}>
            <span>ค้นหาเรื่องราวที่อาจต้องการการรับฟัง</span>
            <span>และจากนั้นเราก็จะเริ่มต้นออกเดินทาง</span>
            <span>อย่างมั่นคงตั้งแต่ต้นปี</span>
            <span>Let's play</span>
          </Trail>
          {formState.step === 1 && (
            <button
              onClick={() =>
                dispatchFromState({
                  type: "CHANGE_STEP",
                  payload: 2.1,
                })
              }
              className="bg-gradient-to-r from-teal-500 to-orange-500 absolute bottom-20 w-64 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </button>
          )}

          {formState.step === 2.1 && (
            <button
              onClick={() =>
                dispatchFromState({
                  type: "CHANGE_STEP",
                  payload: 2.2,
                })
              }
              className="bg-gradient-to-r from-teal-500 to-orange-500 absolute bottom-20 w-64 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </button>
          )}
          {formState.step === 2.2 && (
            <button
              onClick={() =>
                dispatchFromState({
                  type: "CHANGE_STEP",
                  payload: 2.3,
                })
              }
              className="bg-gradient-to-r from-teal-500 to-orange-500 absolute bottom-20 w-64 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </button>
          )}
          {formState.step === 2.3 && (
            <button
              onClick={scrollToSecondSection}
              className="bg-gradient-to-r from-teal-500 to-orange-500 absolute bottom-20 w-64 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </button>
          )}
          <Trail open={formState.step === 3}>
            <span>เชื่อว่าในปีที่ผ่านมา </span>
            <span>คุณอาจผ่านเรื่องราวการทำงาน</span>
            <span>ทั้งสุขและทุกข์มากมายใช่มั้ย</span>
          </Trail>
          <Trail open={formState.step === 4} height={40}>
            <span>อยากเล่าให้เราฟังมั้ย</span>
            <span>ก่อนอื่นเราขอรู้จักคุณได้มั้ย</span>
            <span>คุณชื่ออะไร</span>
          </Trail>

          {formState.step === 3 && (
            <button
              onClick={() =>
                dispatchFromState({
                  type: "CHANGE_STEP",
                  payload: 4,
                })
              }
              className="bg-gradient-to-r from-teal-500 to-orange-500 absolute bottom-20 w-64 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </button>
          )}

          {formState.step === 4 && (
            <>
              <div>
                <input
                  defaultValue={state.name}
                  type="text"
                  className="w-64 rounded-lg p-2"
                  onChange={(e) => {
                    dispatch({
                      type: "NAME_INPUT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 5,
                  })
                }
                disabled={state.name.length < 2}
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </>
          )}

          {formState.step === 5 && (
            <>
              <Trail open={formState.step === 5} height={40}>
                <span>แล้วคุณ {state.name}</span>
                <span>ทำงานที่ AOT มากี่ปีแล้วล่ะ</span>
              </Trail>
              <div>
                <input
                  defaultValue={state.experience}
                  type="number"
                  className="w-64 rounded-lg p-2"
                  onChange={(e) => {
                    dispatch({
                      type: "EXP_INPUT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 6,
                  })
                }
                disabled={
                  state.experience.length < 1 || state.experience.length > 2
                }
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </>
          )}

          {state.experience <= 5 && formState.step === 6 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2 }}
            >
              <p className="text-center text-xl text-gray-200">
                การเริ่มต้นเป็นสิ่งที่ดี
              </p>
              <p className="text-center text-xl text-gray-200">
                เหนื่อยหน่อยแต่ก้าวต่อไปนะ
              </p>
            </motion.div>
          )}
          {state.experience > 5 &&
            state.experience <= 10 &&
            formState.step === 6 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}
              >
                <p className="text-center text-xl text-gray-200">
                  เดินมาสักพักแล้ว
                </p>
                <p className="text-center text-xl text-gray-200">
                  อาจเหนื่อยหน่อยแต่ก้าวต่อไปนะ
                </p>
              </motion.div>
            )}
          {state.experience > 10 && formState.step === 6 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2 }}
            >
              <p className="text-center text-xl text-gray-200">
                คงผ่านเรื่องราวมาเยอะสินะ
              </p>
              <p className="text-center text-xl text-gray-200">
                แต่สู้ไปด้วยกันอีกหน่อย
              </p>
              <p className="text-center text-xl text-gray-200">
                ทุกคนต้องการความสามารถของคุณ
              </p>
            </motion.div>
          )}

          {formState.step === 6 && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 2 }}
              onClick={() =>
                dispatchFromState({
                  type: "CHANGE_STEP",
                  payload: 7,
                })
              }
              className="absolute bottom-20 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
            >
              ต่อไป
            </motion.button>
          )}

          {formState.step === 7 && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1.25 }}
              >
                <p className="text-center text-xl text-gray-200">
                  ขอบคุณที่เปิดใจเล่า
                </p>
                <p className="text-center text-xl text-gray-200">
                  ให้เรารู้จักกันมากขึ้น
                </p>
              </motion.div>
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1.5 }}
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 8,
                  })
                }
                className="absolute bottom-20 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </motion.button>
            </>
          )}

          {formState.step === 8 && (
            <motion.div
              className="flex flex-col items-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                แล้วปีที่ผ่านมา การทำงานของคุณ
              </p>
              <p className="text-center text-xl text-gray-200">
                มีเรื่องอะไรหนักใจบ้างมั้ย
              </p>
              <div>
                <input
                  defaultValue={state.problem}
                  type="text"
                  className="w-64 rounded-lg p-2 mt-2"
                  onChange={(e) => {
                    dispatch({
                      type: "PROBLEM_INPUT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 9,
                  })
                }
                disabled={state.problem.length < 2}
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 9 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                แล้วเรื่องหนักใจที่เล่าให้ฟัง
              </p>
              <p className="text-center text-xl text-gray-200">
                คุณ{state.name}
                ได้ลองแก้มันหรือยัง
              </p>

              <div className="flex w-64 justify-center gap-4">
                <button
                  onClick={() =>
                    dispatchFromState({
                      type: "CHANGE_STEP",
                      payload: 12,
                    })
                  }
                  disabled={state.problem.length < 2}
                  className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  ลองแล้ว
                </button>
                <button
                  onClick={() =>
                    dispatchFromState({
                      type: "CHANGE_STEP",
                      payload: 10,
                    })
                  }
                  disabled={state.problem.length < 2}
                  className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  ยังเลย
                </button>
              </div>
            </motion.div>
          )}

          {formState.step === 10 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                แล้วถ้ามีโอกาสจะแก้ไขยังไง
              </p>
              <p className="text-center text-xl text-gray-200">
                ไม่มีอะไรผิด พิมพ์มาเลย
              </p>
              <div>
                <input
                  defaultValue={state.solveProblem}
                  type="text"
                  className="w-64 rounded-lg p-2 mt-2"
                  onChange={(e) => {
                    dispatch({
                      type: "SOLVE_PROBLEM_INPUT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 11,
                  })
                }
                disabled={state.solveProblem.length < 2}
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 11 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                ไอเดียของคุณมันก็ดีนะ
              </p>
              <p className="text-center text-xl text-gray-200">
                ไว้มีโอกาสลองลงมือทำดูสิ
              </p>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 14,
                  })
                }
                disabled={state.problem.length < 2}
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 12 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">แล้วได้ผลมั้ย</p>
              <div className="flex w-64 justify-center gap-4">
                <button
                  onClick={() =>
                    dispatchFromState({
                      type: "CHANGE_STEP",
                      payload: 14,
                    })
                  }
                  disabled={state.problem.length < 2}
                  className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  ได้ผลนะ
                </button>
                <button
                  onClick={() =>
                    dispatchFromState({
                      type: "CHANGE_STEP",
                      payload: 13,
                    })
                  }
                  disabled={state.problem.length < 2}
                  className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  ยังไม่ได้ผล
                </button>
              </div>
            </motion.div>
          )}

          {formState.step === 13 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                เราเชื่อว่า {state.experience} ปีที่ผ่านมา
              </p>
              <p className="text-center text-xl text-gray-200">
                คุณทำเต็มที่แล้วล่ะ
              </p>
              <p className="text-center text-xl text-gray-200">อย่ายอมแพ้นะ</p>
              <p className="text-center text-xl text-gray-200">เราเข้าใจ</p>

              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 14,
                  })
                }
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 14 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                แล้วถ้ามีคนกำลังเจอปัญหา
              </p>
              <p className="text-center text-xl text-gray-200">
                ที่ไม่สามารถแก้ไขได้
              </p>
              <p className="text-center text-xl text-gray-200">
                หรือเหนื่อยเกินกว่าจะแก้ไข
              </p>
              <p className="text-center text-xl text-gray-200">
                คุณมีอะไรอยากบอกกับเขามั้ย
              </p>
              <div>
                <input
                  defaultValue={state.somethingToSay}
                  type="text"
                  className="w-64 rounded-lg p-2 mt-2"
                  onChange={(e) => {
                    dispatch({
                      type: "SOMETHING_TO_SAY_INPUT",
                      payload: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 15,
                  })
                }
                disabled={state.somethingToSay.length < 2}
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 15 && (
            <motion.div
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                และวันนี้มีคนอยากบอกอะไรกับคุณ
              </p>
              <p className="text-center text-xl text-gray-200">เช่นเดียวกัน</p>
              <button
                onClick={() =>
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 16,
                  })
                }
                className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </motion.div>
          )}

          {formState.step === 16 && (
            <motion.div
              // ref={pictureRef}
              className="flex flex-col items-center "
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1.25 }}
            >
              <p className="text-center text-xl text-gray-200">
                {state.somethingToSay}
              </p>
              <div className="absolute bottom-20 flex items-center justify-center gap-10 remove-btn">
                <button
                  onClick={onButtonClick}
                  className="bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  บันทึกรูป
                </button>
                <button
                  onClick={() =>
                    dispatchFromState({
                      type: "CHANGE_STEP",
                      payload: 18,
                    })
                  }
                  className="disabled:opacity-50 bg-gradient-to-r from-teal-500 to-orange-500 w-32 mt-4 py-2 rounded-md text-gray-100"
                >
                  ต่อไป
                </button>
              </div>
            </motion.div>
          )}

          {formState.step === 18 && (
            <>
              <Trail open={formState.step === 18} height={40} duration={500}>
                <span>ขอบคุณที่มาเปิดใจ</span>
                <span>ในพื้นที่ปลอดภัยให้เราฟังนะ</span>
                <span>ขอให้ปีนี้เป็นปีที่ดีที่สุดของคุณตลอดไป</span>
              </Trail>
              <button
                onClick={() => {
                  postData();
                  dispatchFromState({
                    type: "CHANGE_STEP",
                    payload: 19,
                  });
                }}
                className="disabled:opacity-50 absolute bottom-20 bg-gradient-to-r from-teal-500 to-orange-500 w-64 mt-4 py-2 rounded-md text-gray-100"
              >
                ต่อไป
              </button>
            </>
          )}

          {formState.step === 19 && (
            <>
              <Trail open={formState.step === 19} height={40} duration={300}>
                <span>ด้วยความหวังดี</span>
                <span>ฝ่ายพัฒนาวิทยาการและนวัตกรรม</span>
                <span>และฝ่ายระบบสารสนเทศ</span>
              </Trail>

              <div className="flex gap-3">
                <FacebookShareButton
                  url="https://inno.airportthai.co.th/"
                  quote="https://inno.airportthai.co.th/"
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <LineShareButton
                  url="https://inno.airportthai.co.th/"
                  title="https://inno.airportthai.co.th/"
                >
                  <LineIcon size={32} round={true} />
                </LineShareButton>
              </div>
            </>
          )}
        </div>
        {/* </motion.section> */}
      </BackgroundImage>
    </div>
  );
}

export default App;
