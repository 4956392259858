export const FORM_STATE_INITIAL_STATE = {
  step: 1,
};

export const formStateReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_STEP":
      return {
        ...state,
        step: action.payload,
      };

    default:
      return state;
  }
};
